const routes = [
    {
        path: "/30/jgg",
        children: [
            {
                path: "20240415/cgq/:test?",
                component: () => import("@/views/30/jgg/2024041501/index.vue"),
                meta: {
                    sign: "20240415cgq",
                },
            },
            {
                path: "20240415/kcw/:test?",
                component: () => import("@/views/30/jgg/2024041501/index.vue"),
                meta: {
                    sign: "20240415kcw",
                },
            },
            {
                path: "20240415/zl/:test?",
                component: () => import("@/views/30/jgg/2024041502/index.vue"),
                meta: {
                    sign: "20240415zl",
                },
            },
            {
                path: "20240415/zgz/:test?",
                component: () => import("@/views/30/jgg/2024041502/index.vue"),
                meta: {
                    sign: "20240415zgz",
                },
            },
            {
                path: "30_20241021yy1/:test?",
                component: () => import("@/views/30/jgg/20241021/index.vue"),
                meta: {
                    sign: "30_20241021yy1",
                },
            },
            {
                path: "30_20241021wj1/:test?",
                component: () => import("@/views/30/jgg/20241021/index.vue"),
                meta: {
                    sign: "30_20241021wj1",
                },
            },
            {
                path: "30_20241021bf2/:test?",
                component: () => import("@/views/30/jgg/20241021/index.vue"),
                meta: {
                    sign: "30_20241021bf2",
                },
            },
            {
                path: "30_20241021fly2/:test?",
                component: () => import("@/views/30/jgg/20241021/index.vue"),
                meta: {
                    sign: "30_20241021fly2",
                },
            },
            {
                path: "30_20241216slz/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216slz",
                },
            },{
                path: "30_20241216cs/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216cs",
                },
            },{
                path: "30_20241216lxq/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216lxq",
                },
            },{
                path: "30_20241216kcw/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216kcw",
                },
            },{
                path: "30_20241216zly/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216zly",
                },
            },{
                path: "30_20241216hf/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216hf",
                },
            },{
                path: "30_20241216qbb/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216qbb",
                },
            },{
                path: "30_20241216zgz/:test?",
                component: () => import("@/views/30/jgg/20241216/index.vue"),
                meta: {
                    sign: "30_20241216zgz",
                },
            },
        ],
    },
];

export default routes;
